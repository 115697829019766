$(function(){
	
	var wh = $(window).height();
	var ww = $(window).width();
	
	$(function () {
		$('[data-toggle="tooltip"]').tooltip()
	})
	
}); 

